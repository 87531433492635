:root {
  --primary-main: #187eb2;
  --secondary-main: white;
}

.map-wrap {
  position: relative;
  width: 100%;
  height: calc(
    100vh - 57px
  ) !important; /* calculate height of the screen minus the heading */
}

@media screen and (min-width: 600px) {
  .map-wrap {
    height: calc(
      100vh - 64px
    ) !important; /* calculate height of the screen minus the heading */
  }
}

@media screen and (min-width: 1000px) {
  .map-wrap {
    height: calc(
      100vh - 72px
    ) !important; /* calculate height of the screen minus the heading */
  }
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  z-index: 10;
}

.mapboxgl-style-switcher {
  background-image: url(data:image/svg+xml,%3Csvg%20stroke%3D%22currentColor%22%20fill%3D%22currentColor%22%20stroke-width%3D%220%22%20viewBox%3D%220%200%20576%20512%22%20height%3D%221em%22%20width%3D%221em%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0%20117.66v346.32c0%2011.32%2011.43%2019.06%2021.94%2014.86L160%20416V32L20.12%2087.95A32.006%2032.006%200%200%200%200%20117.66zM192%20416l192%2064V96L192%2032v384zM554.06%2033.16L416%2096v384l139.88-55.95A31.996%2031.996%200%200%200%20576%20394.34V48.02c0-11.32-11.43-19.06-21.94-14.86z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E) !important;
}

.mapboxgl-legend-switcher {
  background-image: url(data:image/svg+xml,%3Csvg%20stroke%3D%22currentColor%22%20fill%3D%22currentColor%22%20stroke-width%3D%220%22%20viewBox%3D%220%200%20512%20512%22%20height%3D%221em%22%20width%3D%221em%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M12.41%20148.02l232.94%20105.67c6.8%203.09%2014.49%203.09%2021.29%200l232.94-105.67c16.55-7.51%2016.55-32.52%200-40.03L266.65%202.31a25.607%2025.607%200%200%200-21.29%200L12.41%20107.98c-16.55%207.51-16.55%2032.53%200%2040.04zm487.18%2088.28l-58.09-26.33-161.64%2073.27c-7.56%203.43-15.59%205.17-23.86%205.17s-16.29-1.74-23.86-5.17L70.51%20209.97l-58.1%2026.33c-16.55%207.5-16.55%2032.5%200%2040l232.94%20105.59c6.8%203.08%2014.49%203.08%2021.29%200L499.59%20276.3c16.55-7.5%2016.55-32.5%200-40zm0%20127.8l-57.87-26.23-161.86%2073.37c-7.56%203.43-15.59%205.17-23.86%205.17s-16.29-1.74-23.86-5.17L70.29%20337.87%2012.41%20364.1c-16.55%207.5-16.55%2032.5%200%2040l232.94%20105.59c6.8%203.08%2014.49%203.08%2021.29%200L499.59%20404.1c16.55-7.5%2016.55-32.5%200-40z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E) !important;
}

.mapboxgl-legend-list {
  /* overflow-y: none !important; */
  overflow-y: auto !important;
  font: 14px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 10px;
}

.mapboxgl-legend-onlyRendered-label {
  font: 14px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  vertical-align: middle;
}

.mapboxgl-legend-onlyRendered-checkbox {
  margin-left: 7px !important;
  /* display: none !important; */
}

.checkboxEye {
  /* color: #333; */
  cursor: pointer;
  padding: 3px;
  /* border-radius: 2px; */
  /* font-weight: 200; */
}

.check-text {
  vertical-align: middle;
}

/* Styles go here */
.check-text input {
  display: none;
}
.check-text input ~ span {
  color: #333;
  cursor: pointer;
  padding: 6px;
  border-radius: 2px;
  font-weight: 200;
}
.check-text input ~ .checked {
  display: none;
}
.check-text input:checked ~ .checked {
  display: inline-block;
}
.check-text input:checked ~ .unchecked {
  display: none;
}
.check-text input:disabled ~ span {
  color: #999;
  cursor: not-allowed;
}

.mapboxgl-legend-title-label {
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem !important;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  text-align: center;
  margin-bottom: 5px;
  font-weight: 700;
}

.mapboxgl-ctrl-group .mapboxgl-legend-list button {
  margin-top: 8px;
}

.mapboxgl-ctrl button:not(:disabled):hover {
  background-color: #fff !important;
  color: #ec681b;
}

.mapboxgl-ctrl-attrib-button {
  display: none !important;
}

/* .mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  padding: 0 !important;
} */

.mapboxgl-ctrl-attrib.mapboxgl-compact:after {
  padding: 0 !important;
  background-color: white;
}

.mapboxgl-popup-th {
  text-transform: capitalize;
}

.mapboxgl-popup-content {
  color: var(--secondary-main);
  background-color: var(--primary-main);
  border-color: var(--primary-main);
  max-width: 300px;
  box-shadow: 3px 3px 2px grey;
  font-family: 'Arial';
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: var(--primary-main) !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: var(--primary-main) !important;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: var(--primary-main) !important;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: var(--primary-main) !important;
}

.mapboxgl-popup-layer-name {
  text-decoration: none;
  text-transform: capitalize;
  font-size: 16px !important;
  text-decoration: none !important;
}
/* @media screen and (min-width: 481px) { */
td.mapboxgl-popup-td,
th.mapboxgl-popup-th {
  border: none !important;
  background: var(--primary-main) !important;
}
/* } */

/* @media screen and (min-width: 481px) { */
th.mapboxgl-popup-th {
  border-right: 1px solid var(--secondary-main) !important;
  background: var(--primary-main) !important;
  width: 30%;
  word-wrap: break-word;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
td.mapboxgl-popup-td {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
/* } */

.mapboxgl-popup-panel_area {
  background: var(--primary-main) !important;
}

/* @media screen and (min-width: 481px) {
  .mapboxgl-popup-panel_area {
    overflow: hidden !important;
  }
} */

.mapboxgl-popup-close-button {
  color: var(--secondary-main) !important;
}

.legend {
  background-color: #fff;
  border-radius: 4px;
  bottom: 80px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  padding: 10px;
  position: absolute;
  right: 10px;
  z-index: 100;
}

.legend h4 {
  margin: 0 0 10px;
}

.legend div span {
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin-right: 5px;
  width: 10px;
}
